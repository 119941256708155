<template>
  <div>
    <div class="pc container">
      <div class="h5 main" style="padding-top:60px;padding-bottom:28px">내 서비스</div>
      <card-my-service-pc v-for="(card,idx) in list" :key="'card-my-service-'+idx" :card="card" @done="getData"></card-my-service-pc>
      <div v-if="list && list.length === 0" class="empty-view2">내 서비스가 없습니다</div>
    </div>
    <div class="mobile"></div>
    <div class="mobile container">
      <div class="h7 main" style="padding:24px 0 16px 0">내 서비스</div>
      <card-my-service v-for="(card,idx) in list" :key="'card-my-service-'+idx" :card="card"></card-my-service>
      <div v-if="list && list.length === 0" class="empty-view">내 서비스가 없습니다</div>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  //import CardMyService from "../component/CardMyService";
  import CardMyServicePc from "../component/CardMyServicePc";
  import CardMyService from "../component/CardMyService";

  export default {
    name: "MyService",
    components: {
      CardMyService,
      CardMyServicePc,
      //CardMyService
    },
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getData();
    },
    data() {
      return {
        list: []
      }
    },
    methods: {
      getData() {
        this.request.user.get('/launchpack/v1/theme_skin/order?service_type=5')
          .then(res => {
            this.list = res.data.data.map(i => {
              i.plan = i.service_order;
              return i;
            });
          });
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
